import React, { useState, useRef, useEffect, Fragment } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { injectIntl } from 'react-intl';
import { Button } from 'mw-style-react';
import './VideoCarousel.scss';

const VideoCarousel = ({ intl, data: { title, list } }) => {
  const wrapperRef = useRef(null);
  const videoRef = useRef(null);

  const [height, setHeight] = useState(0);
  const [activeSlideIndex, setActiveIndex] = useState(0);

  const refs = [
    useInView({ threshold: 0.6 }),
    useInView({ threshold: 0.6 }),
    useInView({ threshold: 0.6 }),
    useInView({ threshold: 0.6 }),
    useInView({ threshold: 0.6 }),
  ];

  useEffect(() => {
    function handleResize() {
      const contentWidth = videoRef.current.clientWidth;
      setHeight(contentWidth / 1.78);
    }
    handleResize();

    if (window) window.addEventListener('resize', handleResize);
    return () => {
      if (window) window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (refs.length) {
      const index = refs.findIndex(el => el.inView);
      if (index !== -1) {
        setActiveIndex(index);
        const ytVideos = document.getElementsByClassName('ytplayer__iframe') || [];
        [...ytVideos].forEach((v) => {
          v.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        });
      }
    }
  });

  const clickLeftSlide = () => {
    const width = refs[activeSlideIndex].entry.boundingClientRect.width;
    wrapperRef.current.scrollTo({ left: width * (activeSlideIndex - 1) });
  };

  const clickRightSlide = () => {
    const width = refs[activeSlideIndex].entry.boundingClientRect.width;
    wrapperRef.current.scrollTo({ left: width * (activeSlideIndex + 1) });
  };

  const clickDot = i => {
    const width = refs[i].entry.boundingClientRect.width;
    wrapperRef.current.scrollTo({ left: width * i });
  };

  return (
    <div className="videoContainer">
      <div className="videoCarousel">
        <div className="videoCarousel__heading">
          <h2>{intl.formatMessage({ id: title })}</h2>
          <div className="videoCarousel__heading__count">
            <span>
              {activeSlideIndex + 1} / {list.length}
            </span>
          </div>
        </div>
        <div className="videoCarousel__player" ref={wrapperRef}>
          {list.map(({ title: videoTitle, src, list: videoDescription }, i) => (
            <div
              key={videoTitle}
              ref={refs[i].ref}
              className="videoCarousel__player__card"
            >
              <div
                ref={i === 0 ? videoRef : undefined}
                className="videoCarousel__player__card__iframe"
                style={{ height: `${height}px` }}
              >
                <iframe
                  id={`ytplayer-${i}`}
                  className={'ytplayer__iframe'}
                  type="text/html"
                  src={src}
                  width={640}
                  height={360}
                  title={intl.formatMessage({ id: videoTitle })}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              <div className="videoCarousel__player__card__description">
                {videoTitle && (
                  <h3>{intl.formatMessage({ id: videoTitle })}</h3>
                )}
                {videoDescription && (
                  <ul className="description__list__main">
                    {videoDescription.map(item => {
                      const itemWithsubList = typeof item !== 'string';
                      const title = itemWithsubList ? item.title : item;
                      return (
                        <li key={title}>
                          {intl.formatMessage({ id: title })}
                          {itemWithsubList &&
                            item.list && (
                            <ul className="description__list__sublist">
                              {item.list.map(item => (
                                <Fragment key={item}>
                                  {item && intl.formatMessage({ id: item }) !== ' ' && (
                                    <li>
                                      {intl.formatMessage({ id: item })}
                                    </li>
                                  )}
                                </Fragment>

                              ))}
                            </ul>
                            )}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="videoControls">
        <Button
          visibility={activeSlideIndex === 0 ? 'hidden' : 'visible'}
          className="videoControlButton buttonLeft"
          size="large"
          rounded
          icon="arrow"
          onClick={clickLeftSlide}
        />
        <Button
          visibility={
            activeSlideIndex === refs.length - 1 ? 'hidden' : 'visible'
          }
          className="videoControlButton buttonRight"
          size="large"
          rounded
          icon="arrow"
          onClick={clickRightSlide}
        />
      </div>
      <ul className="dotsControls">
        {list &&
          list.map(({ title }, i) => (
            <li
              key={title}
              onClick={() => clickDot(i)}
              role="button"
              aria-label={title}
              aria-labelledby={`ytplayer-${i}`}
              className={cn('dotsControls__item', {
                active: activeSlideIndex === i,
                cumulative: activeSlideIndex > i,
              })}
            />
          ))}
      </ul>
    </div>
  );
};

VideoCarousel.propTypes = {
  data: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(VideoCarousel);
